import * as i0 from '@angular/core';
import { InjectionToken, Directive, Optional, Inject, Attribute, ElementRef, Injectable, NgModule, inject } from '@angular/core';
import * as i1 from 'rxjs';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { WINDOW } from '@ng-web-apis/common';
const INTERSECTION_ROOT = new InjectionToken('Root element for IntersectionObserver');
const INTERSECTION_ROOT_MARGIN_DEFAULT = '0px 0px 0px 0px';
const INTERSECTION_ROOT_MARGIN = new InjectionToken('rootMargin for IntersectionObserver', {
  providedIn: 'root',
  factory: () => INTERSECTION_ROOT_MARGIN_DEFAULT
});
function rootMarginFactory(rootMargin) {
  return rootMargin || INTERSECTION_ROOT_MARGIN_DEFAULT;
}
const INTERSECTION_THRESHOLD_DEFAULT = 0;
const INTERSECTION_THRESHOLD = new InjectionToken('threshold for IntersectionObserver', {
  providedIn: 'root',
  factory: () => INTERSECTION_THRESHOLD_DEFAULT
});
function thresholdFactory(threshold) {
  return (threshold === null || threshold === void 0 ? void 0 : threshold.split(',').map(parseFloat)) || INTERSECTION_THRESHOLD_DEFAULT;
}
const SafeObserver = typeof IntersectionObserver !== `undefined` ? IntersectionObserver : class {
  constructor() {
    this.root = null;
    this.rootMargin = ``;
    this.thresholds = [];
  }
  observe() {}
  unobserve() {}
  disconnect() {}
  takeRecords() {
    return [];
  }
};
class IntersectionObserverDirective extends SafeObserver {
  constructor(root, rootMargin, threshold) {
    super(entries => {
      this.callbacks.forEach((callback, element) => {
        const filtered = entries.filter(({
          target
        }) => target === element);
        return filtered.length && callback(filtered, this);
      });
    }, {
      root: root && root.nativeElement,
      rootMargin: rootMarginFactory(rootMargin),
      threshold: thresholdFactory(threshold)
    });
    this.callbacks = new Map();
  }
  observe(target, callback = () => {}) {
    super.observe(target);
    this.callbacks.set(target, callback);
  }
  unobserve(target) {
    super.unobserve(target);
    this.callbacks.delete(target);
  }
  ngOnDestroy() {
    this.disconnect();
  }
}
IntersectionObserverDirective.ɵfac = function IntersectionObserverDirective_Factory(t) {
  return new (t || IntersectionObserverDirective)(i0.ɵɵdirectiveInject(INTERSECTION_ROOT, 8), i0.ɵɵinjectAttribute('waIntersectionRootMargin'), i0.ɵɵinjectAttribute('waIntersectionThreshold'));
};
IntersectionObserverDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: IntersectionObserverDirective,
  selectors: [["", "waIntersectionObserver", ""]],
  exportAs: ["IntersectionObserver"],
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IntersectionObserverDirective, [{
    type: Directive,
    args: [{
      selector: '[waIntersectionObserver]',
      exportAs: 'IntersectionObserver'
    }]
  }], function () {
    return [{
      type: i0.ElementRef,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [INTERSECTION_ROOT]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Attribute,
        args: ['waIntersectionRootMargin']
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Attribute,
        args: ['waIntersectionThreshold']
      }]
    }];
  }, null);
})();
class IntersectionObserveeService extends Observable {
  constructor({
    nativeElement
  }, observer) {
    super(subscriber => {
      observer.observe(nativeElement, entries => {
        subscriber.next(entries);
      });
      return () => {
        observer.unobserve(nativeElement);
      };
    });
    return this.pipe(share());
  }
}
IntersectionObserveeService.ɵfac = function IntersectionObserveeService_Factory(t) {
  return new (t || IntersectionObserveeService)(i0.ɵɵinject(ElementRef), i0.ɵɵinject(IntersectionObserverDirective));
};
IntersectionObserveeService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: IntersectionObserveeService,
  factory: IntersectionObserveeService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IntersectionObserveeService, [{
    type: Injectable
  }], function () {
    return [{
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [ElementRef]
      }]
    }, {
      type: IntersectionObserverDirective,
      decorators: [{
        type: Inject,
        args: [IntersectionObserverDirective]
      }]
    }];
  }, null);
})();
class IntersectionObserveeDirective {
  constructor(waIntersectionObservee) {
    this.waIntersectionObservee = waIntersectionObservee;
  }
}
IntersectionObserveeDirective.ɵfac = function IntersectionObserveeDirective_Factory(t) {
  return new (t || IntersectionObserveeDirective)(i0.ɵɵdirectiveInject(IntersectionObserveeService));
};
IntersectionObserveeDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: IntersectionObserveeDirective,
  selectors: [["", "waIntersectionObservee", ""]],
  outputs: {
    waIntersectionObservee: "waIntersectionObservee"
  },
  features: [i0.ɵɵProvidersFeature([IntersectionObserveeService])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IntersectionObserveeDirective, [{
    type: Directive,
    args: [{
      selector: '[waIntersectionObservee]',
      outputs: ['waIntersectionObservee'],
      providers: [IntersectionObserveeService]
    }]
  }], function () {
    return [{
      type: i1.Observable,
      decorators: [{
        type: Inject,
        args: [IntersectionObserveeService]
      }]
    }];
  }, null);
})();
class IntersectionRootDirective {}
IntersectionRootDirective.ɵfac = function IntersectionRootDirective_Factory(t) {
  return new (t || IntersectionRootDirective)();
};
IntersectionRootDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: IntersectionRootDirective,
  selectors: [["", "waIntersectionRoot", ""]],
  features: [i0.ɵɵProvidersFeature([{
    provide: INTERSECTION_ROOT,
    useExisting: ElementRef
  }])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IntersectionRootDirective, [{
    type: Directive,
    args: [{
      selector: '[waIntersectionRoot]',
      providers: [{
        provide: INTERSECTION_ROOT,
        useExisting: ElementRef
      }]
    }]
  }], null, null);
})();
class IntersectionObserverModule {}
IntersectionObserverModule.ɵfac = function IntersectionObserverModule_Factory(t) {
  return new (t || IntersectionObserverModule)();
};
IntersectionObserverModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: IntersectionObserverModule
});
IntersectionObserverModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IntersectionObserverModule, [{
    type: NgModule,
    args: [{
      declarations: [IntersectionObserverDirective, IntersectionObserveeDirective, IntersectionRootDirective],
      exports: [IntersectionObserverDirective, IntersectionObserveeDirective, IntersectionRootDirective]
    }]
  }], null, null);
})();
const INTERSECTION_OBSERVER_SUPPORT = new InjectionToken('Intersection Observer API support', {
  providedIn: 'root',
  factory: () => !!inject(WINDOW).IntersectionObserver
});
class IntersectionObserverService extends Observable {
  constructor({
    nativeElement
  }, support, rootMargin, threshold, root) {
    super(subscriber => {
      if (!support) {
        subscriber.error('IntersectionObserver is not supported in your browser');
        return;
      }
      const observer = new IntersectionObserver(entries => {
        subscriber.next(entries);
      }, {
        root: root === null || root === void 0 ? void 0 : root.nativeElement,
        rootMargin,
        threshold
      });
      observer.observe(nativeElement);
      return () => {
        observer.disconnect();
      };
    });
    return this.pipe(share());
  }
}
IntersectionObserverService.ɵfac = function IntersectionObserverService_Factory(t) {
  return new (t || IntersectionObserverService)(i0.ɵɵinject(ElementRef), i0.ɵɵinject(INTERSECTION_OBSERVER_SUPPORT), i0.ɵɵinject(INTERSECTION_ROOT_MARGIN), i0.ɵɵinject(INTERSECTION_THRESHOLD), i0.ɵɵinject(INTERSECTION_ROOT, 8));
};
IntersectionObserverService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: IntersectionObserverService,
  factory: IntersectionObserverService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IntersectionObserverService, [{
    type: Injectable
  }], function () {
    return [{
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [ElementRef]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [INTERSECTION_OBSERVER_SUPPORT]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [INTERSECTION_ROOT_MARGIN]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [INTERSECTION_THRESHOLD]
      }]
    }, {
      type: i0.ElementRef,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [INTERSECTION_ROOT]
      }]
    }];
  }, null);
})();

/**
 * Public API Surface of @ng-web-apis/intersection-observer
 */

/**
 * Generated bundle index. Do not edit.
 */

export { INTERSECTION_OBSERVER_SUPPORT, INTERSECTION_ROOT, INTERSECTION_ROOT_MARGIN, INTERSECTION_ROOT_MARGIN_DEFAULT, INTERSECTION_THRESHOLD, INTERSECTION_THRESHOLD_DEFAULT, IntersectionObserveeDirective, IntersectionObserveeService, IntersectionObserverDirective, IntersectionObserverModule, IntersectionObserverService, IntersectionRootDirective };
